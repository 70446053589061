import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import getToken from '../auth/getToken';
import { useMsal } from '@azure/msal-react';
import axios from 'axios';
import { findUsernameInSessionStorage } from '../helper/findUsername';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Input from '@mui/material/Input';
import Typography from '@mui/material/Typography';

const CustomBackground = () => {
  const navigate = useNavigate();
  const [backgrounds, setBackgrounds] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [username, setUsername] = useState('');
  const [token, setToken] = useState('');
  const [open, setOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState('');
  const [toDelete, setToDelete] = useState('');
  const { instance } = useMsal();

  const loadImage = async (foundUsername, acquiredToken) => {
    const url = `${process.env.REACT_APP_API_URL}/custom-backgrounds/fetch-backgrounds?username=${foundUsername}`;
    console.log("Request URL:", url);

    try {
      const response = await fetch(url, {
        headers: { 'Authorization': `Bearer ${acquiredToken}` }
      });

      if (!response.ok) {
        const errorText = await response.text();
        try {
          const errorJson = JSON.parse(errorText);
          throw new Error(`Failed to fetch backgrounds: ${errorJson.error}`);
        } catch (jsonError) {
          throw new Error(`Failed to fetch backgrounds: ${errorText}`);
        }
      }

      const data = await response.json();
      setBackgrounds(data.backgrounds);
    } catch (error) {
      console.error("Error loading image:", error);
      alert(`Ein Fehler ist aufgetreten: ${error.message}`);
    }
  };

  useEffect(() => {
    const init = async () => {
      const foundUsername = findUsernameInSessionStorage();
      if (foundUsername) {
        setUsername(foundUsername);
        try {
          const acquiredToken = await getToken(instance, [`${process.env.REACT_APP_CLIENT_ID}/.default`]);
          setToken(acquiredToken);
          console.log("Acquired token is", acquiredToken);
          await loadImage(foundUsername, acquiredToken);
        } catch (error) {
          console.error("Error acquiring token:", error);
        }
      }
    };

    init();
  }, [instance]);

  const onSelectFile = event => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
      redirectToCrop(event.target.files[0]);
    }
  };

  const redirectToCrop = (file) => {
    if (file && username && token) {
      navigate('/crop', { state: { file: file, username: username, token: token } });
    } else {
      alert('Please ensure user credentials are loaded.');
    }
  };

  const handleDelete = async () => {
    setConfirmOpen(false);
    const filename = toDelete.split('/').pop();
    if (username) {
      try {
        const token = await getToken(instance, [`${process.env.REACT_APP_CLIENT_ID}/.default`]);
        await axios.delete(`${process.env.REACT_APP_API_URL}/custom-backgrounds/delete-background`, {
          params: { username, filename },
          headers: { 'Authorization': `Bearer ${token}` }
        });
        setBackgrounds(backgrounds.filter(x => x !== toDelete));
        alert('Hintergrund erfolgreich gelöscht!');
      } catch (error) {
        console.error('Error deleting background:', error);
        alert('Fehler beim Löschen des Hintergrundes!');
      }
    }
  };

  const handleImageClick = (bg) => {
    setCurrentImage(`${process.env.REACT_APP_API_URL}${bg}`);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const confirmDelete = (bg) => {
    setToDelete(bg);
    setConfirmOpen(true);
  };

  const goBack = () => {
    navigate('/main');
  };

  return (
    <div style={{ textAlign: 'center', margin: '20px' }}>
      <div style={{ position: 'absolute', top: 0, left: 0, margin: '10px' }}>
        <Button startIcon={<ArrowBackIcon />} onClick={goBack}>
          Zurück
        </Button>
      </div>
      <Typography variant="h3" component="h1" gutterBottom style={{ margin: '40px 0' }}>
        Eigene Hintergründe verwalten
      </Typography>
      <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', marginBottom: '20px' }}>
        {backgrounds.map((bg, index) => (
          <div key={index} style={{ margin: 10, width: '100px', textAlign: 'center' }}>
            <img src={`${process.env.REACT_APP_API_URL}${bg}`} alt="Custom Background" style={{ width: '100%', height: '100px', objectFit: 'cover', cursor: 'pointer' }} onClick={() => handleImageClick(bg)} />
            <Typography variant="body2">{bg.split('/').pop()}</Typography>
            <IconButton color="error" onClick={() => confirmDelete(bg)}>
              <DeleteIcon />
            </IconButton>
          </div>
        ))}
      </div>
      <label htmlFor="contained-button-file" style={{ display: 'block', margin: '20px auto' }}>
        <Input accept="image/*" id="contained-button-file" multiple type="file" style={{ display: 'none' }} onChange={onSelectFile} />
        <Button 
          variant="contained" 
          component="span" 
          color="primary" 
          style={{ marginBottom: '10px' }}
        >
          Neues Hintergrundbild auswählen und zuschneiden
        </Button>
      </label>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title">
          <IconButton aria-label="close" onClick={handleClose} sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <img src={currentImage} alt="Full Size Custom Background" style={{ width: '100%' }} />
      </Dialog>
      <Dialog
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Wirklich löschen?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Wollen Sie den Hintergrund wirklich löschen?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmOpen(false)} color="primary">
            Abbrechen
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            Bestätigen
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CustomBackground;
