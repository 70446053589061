import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Snackbar, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download"; // Import für den Download-Icon
import { Alert } from "@mui/material";
import getToken from "../auth/getToken";
import { useMsal } from "@azure/msal-react";
import { findUsernameInSessionStorage } from "../helper/findUsername";
import EditIcon from "@mui/icons-material/Edit"; // Import the edit icon
import Typography from '@mui/material/Typography';

const Gallery = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [recordings, setRecordings] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [username, setUsername] = useState("");
  const [sortOrder, setSortOrder] = useState("descending"); // oder 'descending'
  const { instance } = useMsal(); // Verwende MSAL-Instanz
  const [token, setToken] = useState(null);

  useEffect(() => {
    const fetchUsername = async () => {
      const username = await findUsernameInSessionStorage();
      setUsername(username);
    };

    fetchUsername().catch(console.error);
  }, []);

  useEffect(() => {
    const fetchRecordings = async () => {
      if (!username) return;

      try {
        const token = await getToken(instance, [
          `${process.env.REACT_APP_CLIENT_ID}/.default`,
        ]);
        setToken(token);
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/view-recordings/${username}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const data = await response.json();
        if (!response.ok) {
          throw new Error(data.error);
        }

        if (data.recordings) {
          const sortedRecordings = [...data.recordings].sort((a, b) =>
            sortOrder === "ascending" ? a.localeCompare(b) : b.localeCompare(a)
          );
          setRecordings(sortedRecordings);
        } else {
          console.error("Keine Aufnahmen gefunden");
        }
      } catch (error) {
        console.error("Fehler beim Abrufen der Aufnahmen:", error);
      }
    };

    fetchRecordings();
  }, [username, sortOrder, instance]);

  const formatRecordingName = (name) => {
    const dateTime = name.substring(0, 14);
    const year = dateTime.substring(0, 4);
    const month = dateTime.substring(4, 6);
    const day = dateTime.substring(6, 8);
    const hour = dateTime.substring(8, 10);
    const minute = dateTime.substring(10, 12);
    const second = dateTime.substring(12, 14);
    const formattedDateTime = `${day}.${month}.${year} ${hour}:${minute}:${second}`;
    return name.includes('_trimmed') ? `${formattedDateTime} (Zugeschnitten)` : formattedDateTime;
};

  const goBack = () => {
    navigate(-1);
  };

  const confirmDelete = (recording) => {
    const confirm = window.confirm(
      `Möchten Sie das Video '${formatRecordingName(
        recording
      )}' wirklich löschen?`
    );
    if (confirm) {
        console.log("video to delete", recording)
      deleteVideo(recording);
    }
  };

  const deleteVideo = async (recording) => {
    try {
      const token = await getToken(instance, [
        `${process.env.REACT_APP_CLIENT_ID}/.default`,
      ]);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/view-recordings/delete-recordings/${username}/${recording}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Fehler beim Löschen der Aufnahme");
      }

      await response.json();
      setRecordings(recordings.filter((file) => file !== recording));
      setOpenSnackbar(true);
    } catch (error) {
      console.error("Fehler beim Löschen der Aufnahme:", error);
    }
  };

  const handleDownload = (recording) => {
    const url = `${process.env.REACT_APP_API_URL}/videos/${username}/recordings/${recording}`;
    fetch(url, {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${token}`, // Annahme, dass der Token noch im state gespeichert ist
      }),
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Erstellen eines neuen Links im Dokument
        const blobUrl = window.URL.createObjectURL(blob);
        const downloadLink = document.createElement("a");
        downloadLink.href = blobUrl;
        downloadLink.setAttribute("download", recording); // Setzen des Dateinamens
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        window.URL.revokeObjectURL(blobUrl); // Freigeben der Blob URL
      })
      .catch((e) => console.error("Download Error:", e));
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const changeSortOrder = (order) => {
    setSortOrder(order);
  };

  const handleEdit = async (recording) => {
    try {
      const token = await getToken(instance, [
        `${process.env.REACT_APP_CLIENT_ID}/.default`,
      ]);
      console.log("übergebenes", username, token);
      navigate(`/edit-video/${recording}`, { state: { token, username } });
    } catch (error) {
      console.error("Error getting token for edit:", error);
    }
  };

  return (
    <div>
      <div style={{ position: "absolute", top: 0, left: 0, margin: "10px" }}>
        <Button startIcon={<ArrowBackIcon />} onClick={goBack}>
          Zurück
        </Button>
      </div>

      <Typography
        variant="h4"
        component="h1"
        gutterBottom
        sx={{ textAlign: "center", my: 3 }}
      >
        Galerie von {username}
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{ textAlign: "center", fontStyle: "italic", mb: 2 }}
      >
        Nach 60 Tagen werden die Aufnahmen automatisch gelöscht!
      </Typography>
      <Button onClick={() => changeSortOrder("ascending")}>Aufsteigend</Button>
      <Button onClick={() => changeSortOrder("descending")}>Absteigend</Button>

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-around",
        }}
      >
        {recordings.map((recording) => (
          <div
            key={recording}
            style={{ width: "30%", marginBottom: "20px", padding: "1%" }}
          >
            <video controls width="100%">
              <source
                src={`${process.env.REACT_APP_API_URL}/videos/${username}/recordings/${recording}`}
                type="video/mp4"
              />
              Ihr Browser unterstützt das Video-Tag nicht.
            </video>
            <div style={{ textAlign: "center" }}>
              <Typography variant="body1">
                {formatRecordingName(recording)}
              </Typography>
              <IconButton
                onClick={() => handleDownload(recording)}
                color="primary"
              >
                <DownloadIcon />
              </IconButton>
              <IconButton
                onClick={() => handleEdit(recording)}
                color="secondary"
                aria-label="edit"
              >
                <EditIcon />
              </IconButton>
              <IconButton
                onClick={() => confirmDelete(recording)}
                color="error"
              >
                <DeleteIcon />
              </IconButton>
            </div>
          </div>
        ))}
      </div>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: "100%" }}
        >
          Video erfolgreich gelöscht
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Gallery;
